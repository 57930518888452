import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { RootState } from "./AppStoreRegistryStore";
import {
  AssessmentSessionV1,
  CopyAssessmentSessionStateV1,
} from "../_domain-model/AssessmentSessionV1.model";
import { AssessmentStateV1 } from "../_domain-model/CustomerAssessment.model";
import { cloneAssessment } from "../_domain-model/services/CustomerAssessmentFactory.service";

const initialState: CopyAssessmentSessionStateV1 = {
  session: undefined,
  sourceAssessment: undefined,
  selectedAssessment: undefined,
};

const init = (
  state: WritableDraft<CopyAssessmentSessionStateV1>,
  payload: string | null | undefined,
) => {
  console.log("init copy-assessment-session: ", payload);
  state.session = undefined;
  state.sourceAssessment = undefined;
  state.selectedAssessment = undefined;
};

const selectAssessmentSession = (
  state: WritableDraft<CopyAssessmentSessionStateV1>,
  payload: AssessmentSessionV1,
) => {
  console.log("select assessment: ", payload);
  state.session = { ...payload };
  state.sourceAssessment = undefined;
  state.selectedAssessment = undefined;
};

const setAssessemnt = (
  state: WritableDraft<CopyAssessmentSessionStateV1>,
  payload: AssessmentStateV1 | null | undefined,
) => {
  console.log("set assessment: ", { payload });
  // PURPOSE
  if (
    payload?.assessment?.purpose?.customerPurposeComment &&
    !payload?.assessment?.purpose?.comment?.includes(
      payload.assessment.purpose.customerPurposeComment,
    )
  ) {
    payload.assessment.purpose.comment += `<br><br>${payload.assessment.purpose.customerPurposeComment}`;
    payload.assessment.purpose.customerPurposeComment = null;
  }

  // Wealth
  if (
    payload?.assessment?.originOfWealth?.comment &&
    !payload.assessment?.originOfFunds?.comment?.includes(
      payload.assessment.originOfWealth.comment,
    )
  ) {
    payload.assessment.originOfFunds.comment += `<br><br>${payload.assessment.originOfWealth.comment}`;
    payload.assessment.originOfWealth.comment = null;
  }
  // TPA
  const comments = [
    payload?.assessment?.transactionPatternAnalysis?.comment2,
    payload?.assessment?.transactionPatternAnalysis?.comment3,
    payload?.assessment?.transactionPatternAnalysis?.comment4,
    payload?.assessment?.transactionPatternAnalysis?.comment5,
  ];

  comments.forEach((comment) => {
    if (
      comment &&
      !payload?.assessment?.purpose?.tpaComment.includes(comment)
    ) {
      payload.assessment.purpose.tpaComment += `<br><br>${comment}`;
      comment = null;
    }
  });

  if (!payload || payload == null) {
    state.sourceAssessment = undefined;
    state.selectedAssessment = undefined;
  } else {
    state.sourceAssessment = cloneAssessment(payload.assessment);
    state.selectedAssessment = cloneAssessment(payload.assessment);
  }
};

const resetAssessemnt = (
  state: WritableDraft<CopyAssessmentSessionStateV1>,
  payload: string,
) => {
  console.log("set : ", payload);
  state.sourceAssessment = null;
  state.selectedAssessment = null;
};

export const CopyAssessmentSessionStore = createSlice({
  name: "CopyAssessmentSessionStore",
  initialState,
  reducers: {
    initCopyAssessmentAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      init(state, action.payload);
    },
    selectAssessmentSessionAction: (
      state,
      action: PayloadAction<AssessmentSessionV1>,
    ) => {
      selectAssessmentSession(state, action.payload);
    },
    setSelectedAssessemntAction: (
      state,
      action: PayloadAction<AssessmentStateV1 | null | undefined>,
    ) => {
      setAssessemnt(state, action.payload);
    },
    resetSelectedAssessemntAction: (state, action: PayloadAction<string>) => {
      resetAssessemnt(state, action.payload);
    },
  },
});

export const {
  initCopyAssessmentAction,
  selectAssessmentSessionAction,
  setSelectedAssessemntAction,
  resetSelectedAssessemntAction,
} = CopyAssessmentSessionStore.actions;

export const selectCopyAssessmentSelectedSessionState = (state: RootState) =>
  state.CopyAssessmentSessionStore?.session;
export const selectCopyAssessmentSelectedAssessmentState = (state: RootState) =>
  state.CopyAssessmentSessionStore?.selectedAssessment;

export default CopyAssessmentSessionStore.reducer;
